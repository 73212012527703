@font-face {
  font-family: "yekan";
  src: url("/src/assets/fonts/Yekan/YekanBakhFaNumRegular.ttf")
      format("truetype"),
    url("/src/assets/fonts/Yekan/YekanBakhFaNumRegular.woff") format("woff");
}
@font-face {
  font-family: "yekan-med";
  src: url("/src/assets/fonts/Yekan/YekanBakhFaNumMedium.ttf")
      format("truetype"),
    url("/src/assets/fonts/Yekan/YekanBakhFaNumMedium.woff") format("woff");
}
@font-face {
  font-family: "yekan-bold";
  src: url("/src/assets/fonts/Yekan/YekanBakhFaNumBold.ttf") format("truetype"),
    url("/src/assets/fonts/Yekan/YekanBakhFaNumBold.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .zm-DaysButton[data-in-range="true"]:before,
.zm-DaysButton[data-end-range="true"]:before {
  right: unset !important;
  left: 20% !important;
}

.css-pw7rye {
  width: unset !important;
} */

/* url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/old_map_@2X.png); */

.grad {
  width: 100%;
  height: 400px;
  background: linear-gradient(
    45deg,
    transparent 25%,
    rgba(0, 0, 0, 0.01) 25%,
    rgba(0, 0, 0, 0.01) 50%,
    transparent 50%,
    transparent 75%,
    rgba(0, 0, 0, 0.01) 75%,
    rgba(0, 0, 0, 0.01)
  );
  background-size: 10px 10px;
}

.Toastify__toast-body {
  direction: rtl;
}

Toastify__toast-body {
  @extend .yekan-med;
}

.yekan,
.yekan * {
  font-family: yekan;
}
.yekan-bold,
.yekan-bold * {
  font-family: yekan-bold;
}
.yekan-med,
.yekan-med * {
  font-family: yekan-med;
}

.fs-85 {
  font-size: 0.85rem;
}

.sidebar .logo-img img {
  max-width: 80px !important;
  max-height: 80px !important;
}

.modal-header .btn-close {
  margin: unset !important;
}

.table > thead > tr > th {
  font-size: 14px !important;
  text-align: right !important ;
}

.info-badge {
  position: absolute;
  top: 20%;
  z-index: 1080;
  right: 20px;
  border-radius: 5px !important;
}

.second-modal-back {
  z-index: 1055 !important;
}
.second-modal-dialog {
  z-index: 1056 !important;
}
.uploader-preview {
  max-width: 400px;
  margin: 10px 10px 10px 10px;
}

.image-uploader {
  width: 15rem;
  margin: 10px;
  text-align: center;
}

.modalBody {
  min-width: 30rem;
}

.dx-scheduler-cell-sizes-horizontal {
  max-width: 5rem !important;
}
.dx-scheduler-cell-sizes-vertical {
  height: 50px !important;
}

/* .dx-item.dx-scheduler-appointment.dx-resizable {
  height: 3rem !important;
  transform: translate(0px, 5px) !important;
} */

/* devextereme loading button */
#small-indicator,
#medium-indicator,
#large-indicator {
  vertical-align: middle;
  margin-right: 10px;
}
#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.indicators {
  display: flex;
  align-items: center;
}

/* custom bootstrap color */

/*------------------------------------
- COLOR personal
------------------------------------*/
.alert-personal {
  color: #183a57;
  background-color: #c6dcef;
  border-color: #b6d2ea;
}

.page-item.active .page-link {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.alert-personal hr {
  border-top-color: #a2c6e5;
}

.alert-personal .alert-link {
  color: #0d1f2f;
}

.badge-personal {
  color: #fff;
  background-color: #337ab7;
}

.badge-personal[href]:hover,
.badge-personal[href]:focus {
  color: #fff;
  background-color: #275f8f;
}

.bg-personal {
  background-color: #337ab7 !important;
}

a.bg-personal:hover,
a.bg-personal:focus,
button.bg-personal:hover,
button.bg-personal:focus {
  background-color: #275f8f !important;
}

.border-personal {
  border-color: #337ab7 !important;
}

.btn-personal {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.btn-personal:hover {
  color: #fff;
  background-color: #2b679b;
  border-color: #275f8f;
}

.btn-personal:focus,
.btn-personal.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.5);
}

.btn-personal.disabled,
.btn-personal:disabled {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.btn-personal:not(:disabled):not(.disabled):active,
.btn-personal:not(:disabled):not(.disabled).active,
.show > .btn-personal.dropdown-toggle {
  color: #fff;
  background-color: #275f8f;
  border-color: #245783;
}

.btn-personal:not(:disabled):not(.disabled):active:focus,
.btn-personal:not(:disabled):not(.disabled).active:focus,
.show > .btn-personal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.5);
}

.btn-outline-personal {
  color: #337ab7;
  background-color: transparent;
  border-color: #337ab7;
}

.btn-outline-personal:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.btn-outline-personal:focus,
.btn-outline-personal.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.5);
}

.btn-outline-personal.disabled,
.btn-outline-personal:disabled {
  color: #337ab7;
  background-color: transparent;
}

.btn-outline-personal:not(:disabled):not(.disabled):active,
.btn-outline-personal:not(:disabled):not(.disabled).active,
.show > .btn-outline-personal.dropdown-toggle {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.btn-outline-personal:not(:disabled):not(.disabled):active:focus,
.btn-outline-personal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-personal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.5);
}

.list-group-item-personal {
  color: #183a57;
  background-color: #b6d2ea;
}

.list-group-item-personal.list-group-item-action:hover,
.list-group-item-personal.list-group-item-action:focus {
  color: #183a57;
  background-color: #a2c6e5;
}

.list-group-item-personal.list-group-item-action.active {
  color: #fff;
  background-color: #183a57;
  border-color: #183a57;
}

.table-personal,
.table-personal > th,
.table-personal > td {
  background-color: #b6d2ea;
}

.table-hover .table-personal:hover {
  background-color: #a2c6e5;
}

.table-hover .table-personal:hover > td,
.table-hover .table-personal:hover > th {
  background-color: #a2c6e5;
}

.text-personal {
  color: #337ab7 !important;
}

a.text-personal:hover,
a.text-personal:focus {
  color: #275f8f !important;
}

.bg-dashboard {
  /* background-color: floralwhite; */
  border-left: 1px solid gray;
}

.table .td-actions {
  min-width: 70px !important;
  padding-right: 0 !important;
}
