@import url("https://fonts.googleapis.com/css?family=Vazirmatn");

/* * {
  box-sizing: border-box;
}
body {
  font-family: Vazirmatn, sans-serif;
  text-align: center;
  margin-top: 4rem;
} */
/* input {
  font-family: Vazirmatn, sans-serif;
  text-align: center;
  width: 280px;
  outline: 0;
  border-radius: 8px;
  height: 45px;
  border: 1px solid #dedede;
} */
.css-15c7cx9 {
  z-index: 10000000;
}
.datePickerInput {
  line-height: 32px;
  height: 35px;
  width: 100%;
}
.wrapper.dp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

/* .tab {
  flex-direction: column;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background-color: #f5f5f5;
  gap: 16px;
  padding: 8px;
}
.tabItem {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}
.tabItem:hover {
  color: #3f5152;
}
.tabItem--selected {
  background-color: #ffffff;
} */

.libWrapper {
  /* display: flex; */
  /* justify-content: center; */
  /* flex-direction: column; */
  /* gap: 14px; */
}

@media only screen and (min-width: 600px) {
  .tab,
  .libWrapper {
    flex-direction: row;
  }
}
