/* dashboard list */
.list-container {
  min-height: 440px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.reserve {
  height: 65px;
}

.reserve > img {
  height: 100%;
  width: 95px;
  float: left;
}

.reserve > div {
  padding-left: 10px;
  vertical-align: top;
  line-height: 1rem;
  font-size: 1rem;
  float: left;
}

.reserve > div.price {
  float: right;
  font-size: 1.2rem;
  padding-top: 1rem;
}

.max-vh-100 {
  max-height: 100vh;
}
/* dashboard list */
