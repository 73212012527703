.double-gradient {
  /* display: grid; */
  place-items: center;
  /* box-shadow: #8d5acd; */

  /* height: 200px;
  width: 200px; */
  /* background: url(../img/s2.jpg) center/cover no-repeat fixed; */
}

div.double-gradient {
  height: calc(100vh - 94px);
}

.double-gradient label {
  color: #000 !important;
}
.my-shadow {
  box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.53);
}

.glass {
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 5px !important;
  background-color: rgba(168, 168, 168, 0.45) !important;
  box-shadow: 0 0 10px 1px rgba(180, 171, 171, 0.363) !important;

  backdrop-filter: blur(15px) !important;
}

.content:has(> div.double-gradient) {
  /* styles to apply to the li tag */
  padding: 0;
}

.sidebar .nav li .nav-link p,
body > .navbar-collapse .nav li .nav-link p {
  font-size: 1rem !important;
}

.sidebar.rtl {
  right: 0 !important;
}
.main-panel.rtl {
  float: left;
}
