/* .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.loader {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 250, 0.6);
  z-index: 9999;
}

.spinner {
  /* border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px; */
  animation: xx 3s linear infinite;
}

@keyframes xx {
  0% {
    /* transform: rotateY(0deg); */
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    /* transform: rotateY(50deg); */
  }
}
